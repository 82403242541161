<template>
  <v-layout column>
    <FieldHeader
      v-if="!hideHeader"
      :field="field"
      :patient="patient"
      :session.sync="session"
      :owner="owner"
      :hideCollapseBtn="hideCollapseBtn"
      :collapsed="hideBody"
      @history="onHistory"
      @collapse="onCollapse"
      :status="status"
      :name="name"
      autosave
    ></FieldHeader>
    <v-layout column v-if="!disabled && !hideBody">
      <DRATable :value.sync="data"></DRATable>
      <v-layout align-start class="mt-2">
        <v-icon class="default-icon">mdi-help-circle-outline</v-icon>
        <v-layout column>
          <div class="scale">- Wysokość mostka - Wyjaśnienie - Podaj szerokość rozejścia na
              wysokości mostka podawane w cm lub na szerokość odpowiedniej
              ilości palców
          </div>
          <div class="scale">- 2cm nad pępkiem / wysokość pępka / 2cm pod pępkiem - Podaj szerokość na odpowiedniej wysokości
          </div>
          <div class="scale">- Kresa biała - subiektywna ocena kresy
          </div>
        </v-layout>
      </v-layout>
    </v-layout>
    <DisabledFieldWrapper
      v-else-if="!hideBody"
      :field="field"
      :hideEdit="hideEdit"
      :session="session"
      :owner="owner"
      @edit="onEdit"
      @append="onAppend"
      @copy="onCopy"
    >
      <DRATable :value="data" class="my-2" disabled></DRATable>
    </DisabledFieldWrapper>
  </v-layout>
</template>

<script>
import ModificationMixin from "@/mixins/ModificationMixin";

export default {
  mixins: [ModificationMixin],
  props: {
    field: {},
    session: {},
    patient: {},
    owner: {},
    disabled: {
      type: Boolean,
      default: false,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    hideCollapseBtn: {
      type: Boolean,
      default: false,
    },
    hideEdit: {
      type: Boolean,
      default: false,
    },
    hideBody: {
      type: Boolean,
      default: false,
    },
    syncId: {
      type: String,
      default: "patientCard",
    },
    clientOnlyPredefined: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: ""
    }
  },
  computed: {
    data: {
      get() {
        if (!this.field.data) {
          this.$set(this.field, "data", {
            date: "",
            sternum_height: "",
            above_belly_button: "",
            belly_button_height: "",
            below_belly_button: "",
            linea_alba: "",
            curl_up: "",
            test_aslr: "",
            test_ablr: "",
            other: "",
            comment: "",
          });
        }
        return this.field.data;
      },
      set(value) {
        this.$set(this.field, "data", value);
      },
    },
    dataJson: {
      get() {
        return JSON.stringify(this.data);
      },
    },
  },
  components: {
    AutosaveIcon: () => import("@/components/AutosaveIcon.vue"),
    PredefinedRecordMenu: () =>
      import("@/components/menus/PredefinedRecordMenu"),
    FieldHeader: () =>
      import("@/components/delegates/patientcard/fields/FieldHeader"),
    DisabledFieldWrapper: () =>
      import("@/components/delegates/patientcard/fields/DisabledFieldWrapper"),
    DRATable: () => import("@/components/tables/patientcard/DRATable"),
  },
  methods: {
    onEdit() {
      this.$emit("edit");
    },
    onHistory() {
      this.$emit("history");
    },
    onCollapse() {
      this.$emit("collapse");
    },
    onAppend(data) {
      this.$emit("append", data);
    },
    onCopy() {
      this.$emit("copy");
    }
  },
  mounted() {
    if (!this.disabled && this.autosave) {
      this.startWatching("dataJson");
    }
  },
};
</script>

<style lang="scss" scoped>
.pch-text-wrapper {
  border-radius: 15px;
  background-color: white;
}
.pch-text {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}
.pch-text ::v-deep p {
  margin-bottom: 0 !important;
}
.default-shadow {
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1) !important;
}
.scale-title {
  font-weight: bold !important;
  font-size: 13px !important;
  height: 18px !important;
}
.scale {
  font-size: 12px !important;
  line-height: 18px !important;
  color: black !important;
}
.default-icon {
  margin-top: 2px;
  font-size: 15px !important;
  min-width: 16px !important;
  height: 16px !important;
  color: var(--v-icons-base) !important;
}
</style>